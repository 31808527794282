main {
	min-height: 80vh;
}

.hover-animate {
	transition: transform 0.3s ease-in-out;
}

.hover-animate:hover {
	transform: scale(1.05);
}

.rating span {
	margin: 0.1rem;
}

.rating svg {
	color: #f8e825;
}

.rating-text {
	font-size: 0.8rem;
	font-weight: 600;
	padding-left: 0.5rem;
}

table td,
table th {
	text-align: center;
}

.review {
	margin-top: 30px;
}

.review h2 {
	font-size: 24px;
	background: #f4f4f4;
	padding: 10px;
	border: 1px solid #ddd;
}

.review button {
	margin-top: 10px;
}

/* Navbar START*/
.ct-navbar {
	background-color: #1d1d1d;
}
.ct-nav-link {
	color: #fff; /* the link color of the navbar*/
}
.ct-nav-link-dark {
	color: #333; /* the link color of the navbar*/
}
.ct-nav-link[href='/cart'] {
	color: #fff; /* the link color of cart*/
}
.ct-nav-link[href='/login'] {
	color: #fff; /* the link color of login*/
}
.ct-nav-link:hover,
.ct-nav-link-dark:hover {
	color: #04dd04;
	/* font-weight: bold; */
}
.ct-nav-dd-link:hover,
.ct-nav-dd-link-dark:hover {
	color: #04dd04;
	/* font-weight: bold; */
}

.search-button:hover {
	color: #fff !important;
	background-color: #04dd04 !important;
}

.nav-link-divider {
	border-bottom: 1px solid #626262;
	padding: 10px;
	border-radius: 5px;
	margin-right: 5px;
}
/* Navbar END*/

/* Cart  START*/
.cart-product-title {
	/* height: 2.5em; 
	overflow: hidden; 
	text-overflow: ellipsis;
	white-space: nowrap;  */
	background-color: #eeeeee;
	padding: 10px;
}
/* Cart  END*/

/* Custom tooltip */

/* Usage: className='tooltip' data-tooltip={content to show} 
Example: className='tooltip' data-tooltip={product.name}*/
.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip:hover::after {
	content: attr(data-tooltip);
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	background-color: #333;
	color: #fff;
	padding: 5px;
	border-radius: 5px;
	white-space: nowrap;
	z-index: 1;
}

/* Product Carousel  START*/
.carousel-caption {
	position: absolute;
	width: 100%;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
}

/* .carousel-caption {
	position: absolute;
	width: 100%;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
} */

.custom-carousel-image {
	height: 100%;
	object-fit: contain;
}

/* For large screens */
@media (min-width: 768px) {
	.custom-carousel .custom-carousel-item {
		height: 500px;
	}
}

/* For small screens */
@media (max-width: 767px) {
	.custom-carousel .custom-carousel-item {
		height: 400px;
	}
	.second-item-image {
		margin-top: 30px;
	}
}
@media (max-width: 900px) {
	.first-item-image {
		margin-top: 70px;
	}
}
/* Product Carousel  END*/

/* floating button  START*/
.fab-button {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

@keyframes blink {
	20% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

.animate-blink {
	animation: blink 2s linear infinite;
}

.whatsapp {
	background-color: #25d366;
	border-color: #25d366;
}
/* floating button  END*/

/* Product card START */
.card-img {
	height: 150px;
	object-fit: contain;
}

.product-title {
	overflow: hidden; /* Hide overflow content */
	text-overflow: ellipsis; /* Add ellipsis for long text */
	white-space: nowrap; /* Prevent wrapping */
}

.card-body {
	padding: 0.2rem;
}
.card {
	width: 100%;
	margin: auto;
}

@media (min-width: 768px) {
	.card-img {
		height: 200px;
		object-fit: contain;
	}
}
/* Product card END */

/* Category START */
.category-header {
	margin: 0;
	padding: 10px;
}

.multi-column-dropdown .dropdown-menu {
	width: 650px;
	margin-top: 30px;
	margin-bottom: 10px;
}

.multi-column-dropdown .dropdown-menu .col {
	width: 150px;
}

.dropdown-item {
	padding: 1rem 1rem;
}

@media (max-width: 768px) {
	.multi-column-dropdown .dropdown-menu {
		width: 100%;
		margin-top: 0px;
	}

	.multi-column-dropdown .dropdown-menu .col {
		width: 50%;
	}

	.dropdown-item {
		padding: 0.5rem 0.2rem;
	}
}
/* Category END */

/* About Us START */
.about-us {
	margin-top: 50px;
	padding: 50px;
	background-color: #f9f9f9;
	border-top: 1px solid #ddd;
}

.about-us h1,
.about-us h2 {
	color: #333;
}

.about-us p {
	color: #555;
	line-height: 1.6;
	max-width: 768px;
}

.about-us ul {
	list-style-type: disc;
	padding-left: 20px;
}

.about-us li {
	margin-bottom: 5px;
}

.about-us h3,
h4 {
	margin-top: 20px;
	color: #444;
}

@media (max-width: 767px) {
	.about-us {
		padding: 10px;
	}
}

/* About Us END */

/* Product Screen START */
.image-scroll-container {
	display: flex;
	align-items: center;
}

.scroll-button {
	background: none;
	border: none;
	cursor: pointer;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	color: #333;
}

.image-scroll {
	display: flex;
	overflow-x: auto;
	scroll-behavior: smooth;
	width: 100%;
}

.image-wrapper {
	flex: 0 0 auto;
	width: 40px;
	margin-right: 10px;
}

/* Product Screen END */
